.label-input {
  display: flex;
  flex-direction: column;

  width: 300px;

  margin: 16px;
}

.adhoc-msg-submit-button,
.rpc-state-display {
  margin: 16px;
}
