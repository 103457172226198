.join-waitlist,
.waitlist-confirmation {
  width: 100%;
  min-height: 151px;

  // border: 3px #ecd9ac solid;
  // border-radius: 13px;

  background-color: #396a4e;
  color: #ecd9ac;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  .paradise-img {
    width: 70%;
    max-width: 350px;
  }
}

.waitlist-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-waitlist-form {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;

  .waitlist-input {
    width: 100%;
    height: 30px;
    padding-left: 8px;
    margin-bottom: 8px;

    background-color: inherit;
    border: 2px solid #ecd9ac;
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    color: #ecd9ac;
    font-size: 16px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
  }

  .waitlist-input:focus {
    outline: none !important;
  }

  .waitlist-input::placeholder {
    color: #ecd9ac;
  }

  .join-button {
    width: 75%;
    height: 42px;

    align-self: center;

    color: #396a4e;
    background-color: #ecd9ac;
    border: 2px solid #ecd9ac;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;

    img {
      width: 80%;
      // max-width: 125px;
    }
  }

  .join-button[disabled] {
    cursor: not-allowed;
    color: #ecd9ac;
  }
}
