.template-input {
  height: 100px;
  width: 250px;
}

.row {
  display: flex;
  flex-direction: row;
}

.test-message {
  padding-left: 8px;

  .subbed-template {
    white-space: pre-line;
    padding-bottom: 8px;
  }
}
