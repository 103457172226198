.login-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
}

.title {
  font-size: 20px;
}

.input {
  padding: 8px;
}

.login-buttom {
  width: 120px;
}
