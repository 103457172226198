.waiver-action {
  background-color: #396a4e;
}

.waiver-submitted {
  background-color: #396a4e;
  color: #ecd9ac;

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waiver-container {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 8px 16px;

  height: 100vh;
}

.waiver-header-img {
  display: block;
  width: 75vw;
  margin-left: auto;
  margin-right: auto;
}

.waiver-text {
  color: #ecd9ac;
  overflow: scroll;

  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 50px;
}

.tiki-waiver-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .tiki-waiver-input {
    height: 30px;
    background-color: inherit;
    border: 2px solid #ecd9ac;
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    color: #ecd9ac;
    font-size: 16px;
  }

  .tiki-waiver-input:focus {
    outline: none !important;
  }

  .tiki-waiver-input::placeholder {
    color: #ecd9ac;
  }

  .waiver-wide-inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80vw;
  }

  .waiver-name-inputs {
    display: flex;
    justify-content: space-between;
  }

  .first-name,
  .last-name {
    width: 35vw;
    padding-left: 8px;
  }

  .email,
  .phone {
    padding-left: 8px;
  }

  .agree-checkbox {
    background-color: inherit;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    color: #ecd9ac;
    font-size: 16px;

    // color of checked box.
    accent-color: #ecd9ac;
  }

  .submit-button {
    background-color: inherit;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    color: #ecd9ac;
    font-size: 16px;

    border: 2px solid #ecd9ac;
    border-radius: 8px;
    cursor: pointer;

    width: 60%;
    height: 42px;
  }
}
