.send-message-page {
  padding: 32px;

  width: 600px;
}

.select-message {
  width: 300px;
}

.message-draft {
  display: flex;

  padding-bottom: 16px;
}

.subbed-message {
  width: 300px;
}
