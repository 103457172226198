.chat-container {
  display: flex;
  height: 400px;
  width: 625px;

  border: 1px solid lightgrey;

  .user-list {
    display: flex;
    flex-direction: column;
    border-right: 1px solid lightgrey;
    overflow-y: auto;
  }

  .user-item {
    padding: 8px;
    width: 150px;
    border-bottom: 1px solid lightgrey;
  }

  .chat-view {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .messages {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 0 8px;
    flex-grow: 1;

    overflow-y: auto;
  }

  .send-message {
    height: 50px;
    border-top: 1px solid lightgrey;
  }

  .chat-item {
    width: 200px;

    border: 1px solid lightgrey;
    border-radius: 5%;

    padding: 4px;
    margin-bottom: 4px;

    &.from-user {
      align-self: flex-start;
    }
    &.to-user {
      align-self: flex-end;
    }
  }
}
