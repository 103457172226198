html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
}

.bg-music {
  display: none;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  padding-top: 60px;
  background-color: #396a4e;
}

.banner-wrapper {
  display: flex;
  justify-content: center;
}

.banner {
  max-width: 350px;
  width: 90%;
  /* max-height: 212px;
  height: 38.1375%; */
}

.reserve-img {
  height: 65%;
  padding-top: 3px;
  padding-left: 8px;
}

.ballad-container {
  max-width: 900px;
  width: 90%;
  padding-top: 50px;
  padding-bottom: 50px;
  /* background-color: aqua; */
  font-size: 12px;
  line-height: 1.75;
  color: #ecd9ac;
  font-family: 'Courier New', Courier, monospace;
}

.ballad-container-m {
  display: none;
  flex-direction: column;
  padding-bottom: 50px;
  font-size: 12px;
  line-height: 1.75;
  color: #ecd9ac;
  font-family: 'Courier New', Courier, monospace;
}

.ballad-column-container {
  display: flex;
  flex-direction: row;
  padding-left: 80px;
}

.ballad-title {
  /* max-width: 850px; */
  /* width: 90%; */
  flex-direction: column;
  /* background-color: red; */
  font-size: 16px;
  text-align: center;
  padding-bottom: 16px;
}

.ballad-column {
  /* background-color: chartreuse; */
  /* width: 100%; */
  margin: auto;
}

.ballad-body-m {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}

.reserve-banner-m {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 24px;
  padding: 8px;
  background-color: #ecd9ac;
}

.contact-us {
  text-align: center;
  color: #ecd9ac;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}

.book-link {
  text-align: center;
  color: #ecd9ac;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;

  padding-top: 24px;
}

.book-link-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-stylng {
  color: #ecd9ac;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  text-align: center;
}

.or {
  margin: 8px 0;
}

.address {
  color: #ecd9ac;
}

.contact-us-img {
  max-width: 250px;
  padding-bottom: 8px;
}

.contact-us-link {
  color: #ecd9ac;
}

@media only screen and (max-width: 800px) {
  .textbody {
    font-size: 14px;
  }
  .ballad-container {
    display: none;
  }
  .ballad-container-m {
    display: flex;
  }

  .ballad-title {
    padding-bottom: 0px;
  }

  .banner-wrapper {
    padding-top: 60px;
    padding-bottom: 25px;
  }

  .banner {
    width: 80%;
  }

  .reserve-banner-m {
    display: inline;
  }

  .reserveButton {
    display: none;
  }
}

.divider {
  display: block;
  max-width: 800px;
  padding-top: 50px;
  width: 90%;
}

.reserveButton {
  width: 300px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.checkout {
  width: 400px;
  margin: auto;
  padding-top: 20%;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.icon {
  width: 25px;
}

.social-icons {
  margin-top: 16px;
}

.instagram-icon {
  margin-right: 12px;
  width: 25px !important;
  height: 25px !important;
}

.tiktok-icon {
  margin-right: 12px;
  width: 25px !important;
  height: 25px !important;
}