.admin-page {
  height: 100vh;
  padding: 16px;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px lightgrey solid;

  height: 30px;
  margin-bottom: 16px;

  .left-side,
  .right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.kiln-logo {
  padding-right: 8px;
}

.tabs-and-view {
  display: flex;
  flex-direction: row;
}

.admin-tabs {
  width: 150px;
  min-height: 80vh;

  border: 1px lightgrey solid;
  padding: 8px;

  .tab {
    display: flex;
    align-items: center;
    height: 30px;

    border-bottom: 1px lightgrey solid;
    cursor: pointer;
  }
}

.view {
  margin: 0 16px 16px 16px;
}
