.user-admin {
  display: flex;

  padding: 0 16px;
  margin-left: 32px;

  height: 500px;
  width: 600px;

  border: 1px black solid;
}

.user-admin {
  .user-items {
    border-right: 1px solid black;
  }

  .user-item {
    // To get the border styling correct.
    position: relative;
    width: 200px;
    padding: 4px;

    cursor: pointer;

    text-align: center;
  }

  .user-item:first-child::before {
    content: '';
    position: absolute;
    left: 25%;
    top: 0;
    height: 1px;
    width: 50%; /* or 100px */
    border-top: 1px black solid;
  }

  .user-item::after {
    content: '';
    position: absolute;
    left: 25%;
    bottom: 0;
    height: 1px;
    width: 50%; /* or 100px */
    border-bottom: 1px black solid;
  }
}

.select-user {
  padding: 8px;
}

.create-new-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;

  .create-user-button {
    width: 75%;
  }
}
