.booking-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  padding-top: 60px;
  background-color: #396a4e;
  color: #ecd9ac; // font

  width: 100%;
  height: 100%;
}

.page-title {
  font-size: 24px;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;

  padding: 16px 0;
}

.google-form {
  width: 100%;
}
