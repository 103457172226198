.title {
  padding: 16px;
  font-size: 40px;
}

.base {
  .message-templates {
    padding-left: 16px;
  }

  .message-area {
    display: flex;
    width: 800px;
  }
}

.new-template-button {
  margin: 16px;
}

.message-templates {
  width: 250px;
}

.message-template {
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;

  .name {
    font-size: 20px;
  }
}

.message-template:first-child {
  border-top: 1px solid black;
}

.message-template:hover {
  background-color: blue;
}

.active-message {
  box-sizing: border-box;
  width: 550px;
  padding: 16px;
}
