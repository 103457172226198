.action-definition {
  display: flex;
  flex-direction: column;

  min-width: 400px;
  min-height: 250px;

  padding: 8px;
  margin: 8px;
  border: 1px black solid;
  border-radius: 5px;
  .action-title {}
}