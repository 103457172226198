.timeslot-page {
  padding: 16px;
}

.editable-timeslot-inputs {
  display: flex;
  width: 400px;
  padding: 8px;
  margin-bottom: 8px;

  border: 1px solid black;
}

.capacity-label {
  padding-left: 16px;
}

#capacity {
  width: 40px;
  margin-left: 4px;
}
