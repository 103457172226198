.vip-waitlist {
  height: 100vh;
  width: 100%;
  background-color: #396a4e;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .tiki-mirage-banner {
    width: 305px;
  }
}

